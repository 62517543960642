export const Slide1 = require('./slide1.png');
export const Slide2 = require('./slide2.png');
export const Slide3 = require('./slide3.png');
export const Slide4 = require('./slide4.png');
export const Technology = require('./technology.jpg');
export const Blog1 = require('./blog1.jpg');
export const Blog2 = require('./blog2.jpg');
export const Blog3 = require('./blog3.jpg');
export const Blog4 = require('./blog4.jpg');
export const Blog5 = require('./blog5.jpg');
export const Blog6 = require('./blog6.jpg');
export const Blog7 = require('./blog7.jpg');
export const Blog8 = require('./blog8.jpg');
export const Logo = require('./logo.png');
export const Servicebnr = require('./service-benner.jpg');
export const Webervicebnr = require('./web-service-banner.jpg');
export const Aboutusimg = require('./aboutus.jpg');
export const Careerbnr = require('./career-benner.jpg');
export const Itrecruitment = require('./it-recruitment.jpg');
export const AboutBnr = require('./aboutu-bnr.jpg');
export const ContactBnr = require('./contact-bnr.jpg');
export const LoginLogoutBnr = require('./login-logout-bg.jpg');
export const JavaImg = require('./java-icon.png');
export const Dotnet = require('./dotnet-icon.png');
export const Scssimg = require('./scss-icon.png');
export const WebDevlcont = require('./webdevelopent-cont.jpg');
export const Csascont = require('./clinical-sas-programming-cont.jpg');
export const Ohcmcont = require('./oraclehcm-cont.jpg');
export const Cgpcont = require('./cgp-cont.jpg');
export const Rpacont = require('./rpa-cont.jpg');
export const Aimlcont = require('./aiml-cont.jpg');
export const Blkchaincont = require('./blockchain-cont.jpg');
export const Iotcont = require('./iot-cont.jpg');
export const Saascont = require('./saas-cont.jpg');
export const Coreimg = require('./corevalues.jpg');
